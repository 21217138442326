import React, { useEffect, useState } from "react";
import Header from "../Menu/Header";
import axios from "axios";
import gifloading from "../../assets/img/loading.gif";
import { Link, useParams } from "react-router-dom";
import { ExportToExcel } from "../Menu/ExportToExcel";

//https://actofcompassionawards.com/wp-json/wp/v2/media

export default function Lead() {
  const [loading, setloading] = useState(false);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [headings, setHeadings] = useState([]);
  const [selectrow, setSelectrow] = useState(null);
  const [paginate, setPaginate] = useState(10);
  const [showAssignPopup, setshowAssignPopup] = useState(null);
  const [showRemarkPopup, setshowRemarkPopup] = useState(null);
  const [users, setUsers] = useState([]);
  const [remarks, setRemarks] = useState([]);

  const param = useParams();
  const getAllData = async () => {
    try {
      setloading(true);
      const response = await axios.get(
        `https://actofcompassionawards.com/wp-json/custom/v1/wpforms-entries/${param.id}`
      );

      if (response?.status === 200 && response?.data) {
        setHeadings(JSON.parse(response?.data?.entries[0]?.fields));
        setData(response?.data?.entries?.reverse());
        setSearchData(response?.data?.entries?.reverse());
      }
      setloading(false);
      getAllUsers();
    } catch (error) {}
  };

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, []);

  const getAllUsers = async () => {
    setloading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/user.php`
      );
      if (response?.status === 200 && response?.data) {
        setUsers(response?.data);
      }
      setloading(false);
      getAllRemark();
    } catch (error) {
      setloading(false);
    }
  };

  const getAllRemark = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/remark.php`
      );
      if (response?.status === 200 && response?.data) {
        setRemarks(response?.data);
      }
    } catch (error) {}
  };

  const assignLeadToUser = async (userId, leadId, formId, status) => {
    const body = {
      leadId,
      formId,
      status,
    };

    try {
      const mydata = {
        id: userId,
        leads: body,
      };
      await axios.put(`${process.env.REACT_APP_API}/api/user.php`, mydata);
      getAllUsers();
    } catch (error) {
      setloading(false);
    }
  };


  const Searching = (text)=>{
    setData(searchData.filter(f => `AOC${f.entry_id}`.toLowerCase().includes(text.toLowerCase())));
  }
  const SearchByName = (text) => {
    setData( searchData.filter(f => 
        Object.values(JSON.parse(f.fields)).some(p => 
            typeof p.value === 'string' && 
            p.value.toLowerCase().includes(text.toLowerCase())
        )
    ));
}
  return (
    <div className="dark:bg-gray-800 dark:text-white min-h-[91.1vh]">
      <Header />
      <section className="lg:ml-48 md:ml-48 pt-16">
        <div className="flex px-10">
          <h1 className="text-3xl  font-extrabold">{data?.post_title}</h1>
          <div className="m-auto bg-red-300">
            <input type="search" name="search" className="h-8 rounded" placeholder="Search by id" onChange={(e)=>Searching(e.target.value)}/>
          </div>
          <div className="m-auto bg-red-300">
            <input type="search" name="search" className="h-8 rounded" placeholder="Search by Name" onChange={(e)=>SearchByName(e.target.value)}/>
          </div>
          <div className="ml-auto">
            <ExportToExcel apiData={data} heading={headings} myremark={remarks} userData={users}/>
          </div>
        </div>

        {selectrow === null && !loading && (
          <div
            className="m-5 border max-w-screen"
            style={{ overflowX: "auto" }}
          >
            <table className="w-full text-left">
              <thead>
                <tr className="bg-blue-900 text-white px-5 text-sm text-left">
                  <th className="min-w-[100px] py-2 pl-2">Sr</th>
                  <th className="min-w-[100px] py-2 pl-2">Actions</th>
                  <th className="min-w-[100px] py-2 pl-2">Id</th>
                  {Object.keys(headings)?.map((key) => {
                    const heading = headings[key];
                    return (
                      <th key={key} className="min-w-[100px] py-2">
                        {heading.name.length > 15
                          ? `${heading.name.slice(0, 15)}...`
                          : heading.name}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 &&
                  data?.slice(0, paginate)?.map((item, index) => (
                    <tr
                      key={index}
                      className={`border border-gray-700 text-left ${
                        index % 2 === 0 && "bg-gray-50"
                      } hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-900`}
                    >
                      <td className="pl-2">{index + 1}</td>
                      <td className="pl-2">
                        <button
                          className="bg-green-400  rounded px-2  m-1"
                          onClick={() => setshowAssignPopup(item)}
                        >
                          Edit
                        </button>
                        <button
                          className="bg-blue-400  rounded px-2  m-1"
                          onClick={() => setshowRemarkPopup(item?.entry_id)}
                        >
                          Remark
                        </button>
                      </td>
                      <td className="pl-2">{"AOC" + data[index]?.entry_id}</td>
                      {/* Parsing the fields JSON string */}
                      {item?.fields &&
                        Object.values(JSON.parse(item.fields))?.map(
                          (value, columnIndex) => (
                            <td
                              key={columnIndex}
                              className="text-sm py-2 min-w-[100px] cursor-pointer"
                              onClick={() => {
                                setSelectrow(item.fields);
                              }}
                            >
                              {value?.type === "file-upload" ? (
                                <Link
                                  to={value?.value}
                                  className="px-2 py-1 bg-blue-500 text-white rounded"
                                  target="_blank"
                                >
                                  Download
                                </Link>
                              ) : value?.value && value.value.length > 15 ? (
                                `${value.value.slice(0, 15)}...`
                              ) : (
                                value?.value
                              )}
                            </td>
                          )
                        )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {loading && (
          <div className="p-20 my-10 bg-gray-50 dark:bg-transparent dark:border dark:border-white">
            <img
              src={gifloading}
              alt="loading..."
              className="w-[100px] m-auto"
            />
          </div>
        )}
      </section>
      {selectrow && selectrow !== null && (
        <section className="absolute top-0 right-0 left-0 bg-white dark:bg-gray-800 min-h-screen">
          <div className="pl-48 m-auto">
            <div className="mt-[50px] px-10 pb-10">
              <div className=" pt-4 flex">
                <h1 className="text-center text-3xl font-bold pb-4">
                  Form Entry Details
                </h1>
                <div className=" ml-auto pt-2">
                  <button
                    className="bg-orange-500 px-8 py-1 text-white rounded text-lg"
                    onClick={() => setSelectrow(null)}
                  >
                    Go Back
                  </button>
                </div>
              </div>
              <table className="max-w-[100vw] w-full ">
                {Object.values(JSON.parse(selectrow))?.map((item, index) => (
                  // <td key={index} dangerouslySetInnerHTML={{ __html: item.html }} className={"py-1  border border-black px-1"}></td>
                  <tr
                    className={` px-5 text-sm dark:bg-gray-700 ${
                      index % 2 === 0 && "bg-gray-100 dark:bg-gray-800"
                    }`}
                    key={index}
                  >
                    <td className="border border-black px-1 font-bold min-w-[200px]">
                      <p>{item?.name}</p>
                    </td>

                    {item?.type !== "file-upload" && (
                      <td
                        className="border border-black px-1"
                        dangerouslySetInnerHTML={{ __html: item?.value }}
                      ></td>
                    )}

                    {item?.type === "file-upload" && (
                      <td className="border border-black px-1">
                        <Link
                          to={item?.value}
                          target="_blank"
                          className="bg-blue-500 px-2 rounded text-white"
                        >
                          Download
                        </Link>
                      </td>
                    )}
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </section>
      )}

      {data?.length > paginate && (
        <div className="flex my-4">
          <button
            className="m-auto w-[200px] bg-blue-600 py-2 px-6 rounded text-white"
            onClick={() => setPaginate(paginate + 10)}
          >
            Load More
          </button>
        </div>
      )}

      {showAssignPopup && (
        <div
          className="fixed top-0 backdrop-blur-sm min-h-screen min-w-[100vw] flex"
          onClick={() => setshowAssignPopup(null)}
        >
          <div
            className="m-auto bg-white h-[330px] max-w-[500px] min-w-[300px] rounded shadow border border-gray-200"
            onClick={(e) => e.stopPropagation()}
          >
            {/* Content of your popup */}
            <h2 className="text-center font-bold">
              Assign Data(AOC{showAssignPopup?.entry_id})
            </h2>
            <div>
              {!loading &&
                users.length > 0 &&
                users.map((item, index) => (
                  <div key={index}>
                    <div className="w-full my-2 px-4 m-auto shadow border border-gray-200 py-2">
                      {item?.role !== "admin" && (
                        <label
                          htmlFor={item?.name}
                          className="cursor-pointer"
                          onClick={() => {
                            assignLeadToUser(
                              item?.id,
                              showAssignPopup?.entry_id,
                              param?.id,
                              true
                            );
                          }}
                        >
                          {item?.name}
                        </label>
                      )}
                      {Object.values(JSON.parse(item?.leads))?.map((item2) => {
                        if (
                          item2?.formId === param?.id &&
                          item2?.leadId === showAssignPopup?.entry_id &&
                          item2?.status
                        ) {
                          return (
                            item?.role !== "admin" && (
                              <input
                                type="checkbox"
                                name={item?.name}
                                className="mx-2 cursor-pointer"
                                checked={true}
                                onChange={(e) => {
                                  assignLeadToUser(
                                    item?.id,
                                    showAssignPopup?.entry_id,
                                    param?.id,
                                    e.target.checked
                                  );
                                }}
                              />
                            )
                          );
                        }
                      })}
                    </div>
                    {/* {item?.role !== 'admin' && <div className="w-full my-2 px-4 m-auto shadow border border-gray-200 py-2">
                      <input
                        type="checkbox"
                        name={item?.name}
                        className="mx-2 cursor-pointer"
                        checked={check}
                        onChange={(e)=>{
                          assignLeadToUser(item?.id ,showAssignPopup?.entry_id,param?.id ,e.target.checked );
                        }}
                      />
                      {item?.name}
                    </div>} */}
                  </div>
                ))}

              {loading && <div>Loading...</div>}
            </div>
            <button
              className="bg-blue-500 w-full text-white py-1 mt-3"
              onClick={() => setshowAssignPopup(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {showRemarkPopup && (
        <div
          className="fixed top-0 backdrop-blur-sm min-h-screen min-w-[100vw] flex"
          onClick={() => setshowRemarkPopup(null)}
        >
          <div
            className="m-auto bg-white h-[400px] w-[500px] rounded shadow border border-gray-200"
            onClick={(e) => e.stopPropagation()}
          >
            {/* Content of your popup */}
            <h2 className="text-center font-bold">
              Remark Data(AOC{showRemarkPopup})
            </h2>
            <div className="">
              { typeof(remarks) === 'object' && remarks?.length > 0 &&
                remarks?.map((item) => (
                  <div>
                    {item?.formId === param?.id && item?.status==='1' && 
                      item?.leadId === showRemarkPopup && (
                        <div className="text-center py-2 my-1  bg-gray-50">
                          {users.map((user) => (
                            <>
                              {user?.id === item?.userId && (
                                <h2 className="font-bold text-sm shadow bg-green-500 text-white py-2">{user?.name}</h2>
                              )}
                            </>
                          ))}
                        <p className="px-2 py-2  shadow"> Rating -  {item?.rating}</p>
                        <p className="px-2 py-2  shadow">{item?.remark}</p>
                        </div>
                      )}
                  </div>
                ))}
            </div>

            <button
              className="bg-blue-500 w-full text-white py-1"
              onClick={()=>{
                setshowRemarkPopup(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
