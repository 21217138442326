import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const ExportToExcel = ({ apiData, heading, myremark, userData }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName = "Exported_Data";

  const exportToCSV = () => {
    const headings = Object.values(heading).map((heading) => heading?.name);

    // Create a new array to hold rows with user data, ratings, and remarks
    let rowsArray = [
      [...headings, "Username", "Rating", "Remark"], // Add Username, Rating, and Remark headings
    ];

    // Map through apiData to extract the relevant data for each row
    apiData?.forEach((item) => {
      const rowData = Object.values(JSON.parse(item?.fields)).map(
        (value) => value.value
      );

      // Find remarks corresponding to this item
      const remark = myremark.find((r) => `${r.leadId}` === item.entry_id);
      
      // Find user corresponding to this item
      const user = userData.find((u) => u.id === remark?.userId || '');
      const username = user ? user.name : "N/A";

      const rating = remark ? remark.rating : "N/A";
      const review = remark ? remark.remark : "N/A";

      rowsArray.push([...rowData, username, rating, review]); // Push row data along with username, rating, and remark
    });

    // // Use aoa_to_sheet to create a sheet with multiple rows
    const ws = XLSX.utils.aoa_to_sheet(rowsArray);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <div className="inline">
        <button
          onClick={exportToCSV}
          className="bg-blue-700 rounded text-white px-5 py-2 my-1 mx-2"
        >
          Export
        </button>
      </div>
    </>
  );
};
