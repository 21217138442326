import React, { useEffect, useState } from "react";
import Header from "../Menu/Header";
import axios from "axios";
import gifloading from "../../assets/img/loading.gif";
import { Link, useParams } from "react-router-dom";
import { ExportToExcel } from "../Menu/ExportToExcel";
import { useFormik } from "formik";
import toast, { Toaster } from "react-hot-toast";

//https://actofcompassionawards.com/wp-json/wp/v2/media

export default function MyLead() {
  const [loading, setloading] = useState(false);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [headings, setHeadings] = useState([]);
  const [selectrow, setSelectrow] = useState(null);
  const [showRemarkPopup, setshowRemarkPopup] = useState(null);
  const [users, setUsers] = useState([]);
  const [remarks, setRemarks] = useState([]);
  const [rating, setrating] = useState(0);
  const [remark, setremark] = useState('');
  const [status, setstatus] = useState(0);
  const [remarkedit, setremarkedit] = useState(false);

  const param = useParams();
  const getAllData = async () => {
    try {
      setloading(true);
      const response = await axios.get(
        `https://actofcompassionawards.com/wp-json/custom/v1/wpforms-entries/${param.id}`
      );

      if (response?.status === 200 && response?.data) {
        setHeadings(JSON.parse(response?.data?.entries[0]?.fields));
        setData(response?.data?.entries?.reverse());
        setSearchData(response?.data?.entries?.reverse());
      }
      setloading(false);
      getAllUsers();
    } catch (error) {}
  };

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, []);

  const getAllUsers = async () => {
    setloading(true);
    const TokenData = localStorage.getItem(process.env.REACT_APP_TOKEN);
    const storedToken = JSON.parse(TokenData) || null;
    const id = parseInt(storedToken?.id);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/singleuser.php?id=${id}`
      );
      if (response?.status === 200 && response?.data) {
        setUsers(response?.data[0]);
      }
      setloading(false);
      getAllRemark();
    } catch (error) {
      setloading(false);
    }
  };

  const getAllRemark = async()=>{
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/remark.php`
      );
      if (response?.status === 200 && response?.data) {
        setRemarks(response?.data);
      }
    } catch (error) {
    }
  }

  const formik = useFormik({
    initialValues: {
      rating: "",
      remark: "",
    },
    onSubmit: async (values) => {
      const TokenData = localStorage.getItem(process.env.REACT_APP_TOKEN);
      const storedToken = JSON.parse(TokenData) || null;
      const userId = parseInt(storedToken?.id);
      const formId = param?.id;
      const leadId = showRemarkPopup;

      const body = {
        userId,
        leadId,
        formId,
        rating:rating,
        remark:remark,
        status:status
      }
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API}/api/remark.php`,body
        );
        setloading(false);
        setshowRemarkPopup(false);
        toast.success(res?.data?.message || 'Something is wrong');
        getAllRemark();
      } catch (error) {
        setloading(0);
      }
    },
  });

  useEffect(()=>{
    setremark('');
    setrating(0);
    setstatus(0);
    setremarkedit(true);
    typeof(remarks) === 'object' && remarks?.length>0 && remarks?.map((item)=>{
        if(item?.formId === param?.id && item?.leadId === showRemarkPopup && item?.userId){
            setrating(item?.rating);
            setremark(item?.remark);
            setstatus(item?.status || false);
            setremarkedit(item?.status==='0' ? true : false);
        }
        return null;
    });
  },[showRemarkPopup,param,remarks]);

  const Searching = (text)=>{
    setData(searchData.filter(f => `AOC${f.entry_id}`.toLowerCase().includes(text.toLowerCase())));
  }
  const SearchByName = (text) => {
    setData( searchData.filter(f => 
        Object.values(JSON.parse(f.fields)).some(p => 
            typeof p.value === 'string' && 
            p.value.toLowerCase().includes(text.toLowerCase())
        )
    ));
}

var count = 0;
  return (
    <div className="dark:bg-gray-800 dark:text-white min-h-[91.1vh]">
      <Header /><Toaster/>
      <section className="lg:ml-48 md:ml-48 pt-16">
        <div className="flex px-10">
          <h1 className="text-3xl  font-extrabold">{data?.post_title}</h1>
          <div className="m-auto bg-red-300">
            <input type="search" name="search" className="h-8 rounded" placeholder="Search by id" onChange={(e)=>Searching(e.target.value)}/>
          </div>
          <div className="m-auto bg-red-300">
            <input type="search" name="search" className="h-8 rounded" placeholder="Search by Name" onChange={(e)=>SearchByName(e.target.value)}/>
          </div>
          <div className="ml-auto">
           {/* <ExportToExcel apiData={data} heading={headings} myremark={[]} userData={[]} /> */}
          </div>
        </div>

        {selectrow === null && !loading && (
          <div
            className="m-5 border max-w-screen"
            style={{ overflowX: "auto" }}
          >
            <table className="w-full text-left">
              <thead>
                <tr className="bg-blue-900 text-white px-5 text-sm text-left">
                  <th className="min-w-[100px] py-2 pl-2">Sr</th>
                  <th className="min-w-[100px] py-2 pl-2">Actions</th>
                  <th className="min-w-[100px] py-2 pl-2">Id</th>
                  {Object.keys(headings).map((key) => {
                    const heading = headings[key];
                    return (
                      <th key={key} className="min-w-[100px] py-2">
                        {heading.name.length > 15
                          ? `${heading.name.slice(0, 15)}...`
                          : heading.name}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 &&
                  data?.map((item, index) => (
                    <>
                      {Object.values(JSON.parse(users?.leads)).map(
                        (item3) =>
                          item3?.formId === param?.id &&
                          item3?.leadId === item?.entry_id &&
                          item3?.status && (
                            <tr
                              key={index}
                              className={`border border-gray-700 text-left ${
                                index % 2 === 0 && "bg-gray-50"
                              } hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-900`}
                            >
                              <td className="pl-2">{++count}</td>
                              <td className="pl-2">
                                <button
                                  className="bg-blue-400  rounded px-2  m-1"
                                  onClick={() =>
                                    setshowRemarkPopup(item?.entry_id)
                                  }
                                >
                                  Remark
                                </button>
                              </td>
                              <td className="pl-2">
                                {"AOC" + data[index]?.entry_id}
                              </td>
                              {/* Parsing the fields JSON string */}
                              {item.fields &&
                                Object.values(JSON.parse(item.fields)).map(
                                  (value, columnIndex) => (
                                    <td
                                      key={columnIndex}
                                      className="text-sm py-2 min-w-[100px] cursor-pointer"
                                      onClick={() => {
                                        setSelectrow(item.fields);
                                      }}
                                    >
                                      {value?.type === "file-upload" ? (
                                        <Link
                                          to={value?.value}
                                          className="px-2 py-1 bg-blue-500 text-white rounded"
                                          target="_blank"
                                        >
                                          Download
                                        </Link>
                                      ) : value?.value &&
                                        value.value.length > 15 ? (
                                        `${value.value.slice(0, 15)}...`
                                      ) : (
                                        value?.value
                                      )}
                                    </td>
                                  )
                                )}
                            </tr>
                          )
                      )}
                    </>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {loading && (
          <div className="p-20 my-10 bg-gray-50 dark:bg-transparent dark:border dark:border-white">
            <img
              src={gifloading}
              alt="loading..."
              className="w-[100px] m-auto"
            />
          </div>
        )}
      </section>
      {selectrow && selectrow !== null && (
        <section className="absolute top-0 right-0 left-0 bg-white dark:bg-gray-800 min-h-screen">
          <div className="pl-48 m-auto">
            <div className="mt-[50px] px-10 pb-10">
              <div className=" pt-4 flex">
                <h1 className="text-center text-3xl font-bold pb-4">
                  Form Entry Details
                </h1>
                <div className=" ml-auto pt-2">
                  <button
                    className="bg-orange-500 px-8 py-1 text-white rounded text-lg"
                    onClick={() => setSelectrow(null)}
                  >
                    Go Back
                  </button>
                </div>
              </div>
              <table className="max-w-[100vw] w-full ">
                {Object.values(JSON.parse(selectrow))?.map((item, index) => (
                  // <td key={index} dangerouslySetInnerHTML={{ __html: item.html }} className={"py-1  border border-black px-1"}></td>
                  <tr
                    className={` px-5 text-sm dark:bg-gray-700 ${
                      index % 2 === 0 && "bg-gray-100 dark:bg-gray-800"
                    }`}
                    key={index}
                  >
                    <td className="border border-black px-1 font-bold min-w-[200px]">
                      <p>{item?.name}</p>
                    </td>

                    {item?.type !== "file-upload" && (
                      <td
                        className="border border-black px-1"
                        dangerouslySetInnerHTML={{ __html: item?.value }}
                      ></td>
                    )}

                    {item?.type === "file-upload" && (
                      <td className="border border-black px-1">
                        <Link
                          to={item?.value}
                          target="_blank"
                          className="bg-blue-500 px-2 rounded text-white"
                        >
                          Download
                        </Link>
                      </td>
                    )}
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </section>
      )}

      {showRemarkPopup && (
        <div
          className="fixed top-0 backdrop-blur-sm min-h-screen min-w-[100vw] flex"
          onClick={() => setshowRemarkPopup(null)}
        >
          <div
            className="m-auto bg-white h-[450px] max-w-[500px] min-w-[300px] rounded shadow border border-gray-200"
            onClick={(e) => e.stopPropagation()}
          >
            {/* Content of your popup */}
            <h2 className="text-center font-bold">
              Remark Data(AOC{showRemarkPopup})
            </h2>
            {remarkedit ? <form onSubmit={formik.handleSubmit}>
              <div className="px-2 my-2">
                <label htmlFor="rating">Rating</label>
                <input
                  type="number"
                  max={10}
                  className="h-8  w-full rounded"
                  name="rating"
                  onChange={(e)=>setrating(e.target.value)}
                  value={rating}
                  required
                />
              </div>

              <div className="px-2 mt-2">
                <label htmlFor="remark">Remark</label>
                <textarea
                  className="h-48 p-1 font-sm w-full rounded"
                  name="remark"
                  onChange={(e)=>setremark(e.target.value)}
                  value={remark}
                  required
                />
              </div>
              <div className="px-2 mb-10">
                <label htmlFor="remark">status</label>
                <select className="w-full h-8 rounded px-2" onChange={(e)=>setstatus(e.target.value)} value={status}>
                  <option value={0}>Draft</option>
                  <option value={1}>Publish</option>
                </select>
              </div>
              <button
                className="bg-blue-500 w-full text-white py-1"
                type="submit"
              >
                Submit
              </button>
            </form>
            :<div className="">
              <h2 className="text-center font-lg mt-20 py-10 shadow">Your Remark is already Submitted</h2>
              <button
                className="bg-green-500 w-full text-white py-1 my-10"
                onClick={()=>setshowRemarkPopup(null)}
              >
                Close
              </button>
              </div>  
          }
            
          </div>
        </div>
      )}
    </div>
  );
}
