import React, { useEffect, useState } from "react";
import Header from "../Menu/Header";
import  { Toaster } from "react-hot-toast";
import axios from "axios";
import gifloading from "../../assets/img/loading.gif";
import { Link } from "react-router-dom";

export default function Dashboard() {
  const [loading, setloading] = useState(false);
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);

  const getAllData = async () => {
    setloading(true);
    try {
      const response = await axios.get(
        "https://actofcompassionawards.com/wp-json/custom/v1/wpforms" );
      setloading(false);
      if (response?.status === 200 && response?.data) {
        setData(response?.data);
      
      }
    } catch (error) {
    }
  };

  useEffect(()=>{
    getAllData();
    getUser();
  },[]);

  const getUser = async () => {
    setloading(true);
    const TokenData = localStorage.getItem(process.env.REACT_APP_TOKEN);
    const storedToken = JSON.parse(TokenData) || null;
    const id = storedToken?.id;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/singleuser.php?id=${id}`
      );
      if (response?.status === 200 && response?.data) {
        setUser(response?.data[0]);
      }
    setloading(false);
    } catch (error) { setloading(false);}
  };

  return (
    <div>
      <Header />
      <Toaster />
      <section className="lg:ml-48 d:ml-48 pt-16 px-4 dark:bg-gray-800 dark:text-white min-h-[91.1vh]">
        <h1 className="text-3xl text-center font-extrabold mb-6">
          Act of Compassion Dashboard
        </h1>
        {!loading && user?.role === 'admin' && <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4">
          {data?.length > 0 &&
            data?.map((item) => (
              <Link to={`/leads/${item?.form_id}`} key={item?.form_id}>
              <div className="shadow-sm cursor-pointer text-center text-2xl font-bold border border-gray-300 rounded p-10 hover:shadow-2xl hover:bg-gray-50 transition duration-900">
                <p className="text-center mb-6"><span className="text-5xl font-extrabold font-sans bg-blue-400  shadow-xl px-6 py-1 rounded-full">{item?.total_entries}</span></p>
                <h1>{item?.form_name}</h1>
              </div>
              </Link>
            ))}
        </div>}


        {!loading && user?.role !== 'admin' && <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4">
          {data?.length > 0 &&
            data?.map((item) => (
              <Link to={`/myleads/${item?.form_id}`} key={item?.form_id}>
              <div className="shadow-sm cursor-pointer text-center text-2xl font-bold border border-gray-300 rounded p-10 hover:shadow-2xl hover:bg-gray-50 transition duration-900">
                <h1>{item?.form_name}</h1>
              </div>
              </Link>
            ))}
        </div>}
        
        {loading && (
          <div className="p-20 bg-gray-50 dark:bg-transparent dark:border dark:border-white">
            <img
              src={gifloading}
              alt="loading..."
              className="w-[100px] m-auto"
            />
          </div>
        )}
      </section>
    </div>
  );
}
