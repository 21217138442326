import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.png";
import { Link, NavLink } from "react-router-dom";
import profile from '../../assets/img/profile.png';

export default function Header() {
  const [userpopup, setUserpopup] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [token, setToken] = useState([]);

  useEffect(() => {
    // Fetch token from localStorage
    const TokenData = localStorage.getItem(process.env.REACT_APP_TOKEN);
    const storedToken = JSON.parse(TokenData) || null;
    setToken(storedToken);

  }, []);
  return (
    <div>
      <nav className="shadow fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <button
                onClick={()=>setSidebar(!sidebar)}
                type="button"
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <Link to="/" className="flex ms-2 md:me-24">
                <img src={logo} className="h-8 w-20 me-3 scale-[1.3]" alt="Logo" />
              </Link>
            </div>
            <div className="flex items-center">
              <div className="flex items-center ms-3">
                <div>
                  <button
                    type="button"
                    className="flex text-sm bg-white rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                    onClick={()=>{setUserpopup(!userpopup);
                      setTimeout(() => {
                        setUserpopup(false);
                      }, 3000);}}
                  >
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="w-8 h-8 rounded-full"
                      src={profile}
                      alt="user"
                    />
                  </button>
                </div>
                {userpopup && <div
                  className="absolute top-10 right-10 bottom-0"
                >
                  <div className="z-50  my-4  dark:bg-gray-800 dark:border border-white text-base list-none divide-y divide-gray-100 rounded shadow bg-white " > 
                    <div className="px-4 py-3" role="none">
                    <p
                      className="text-sm text-gray-900 dark:text-white"
                      role="none"
                    >
                     {token?.name}
                    </p>
                    <p
                      className="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
                      role="none"
                    >
                      {token?.email}
                    </p>
                  </div>
                  <ul className="py-1" role="none">
                    <li>
                      <Link
                        to="/"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                        role="menuitem"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={()=>{
                          localStorage.removeItem(process.env.REACT_APP_TOKEN);
                          window.location.href='/login'
                        }}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                        role="menuitem"
                      >
                        Log out
                      </Link>
                    </li>
                  </ul>
                  </div>
                  
                </div>}
                
              </div>
            </div>
          </div>
        </div>
      </nav>
      <aside
        id="logo-sidebar"
        className={`shadow shadow-orange-400 fixed top-0 left-0 z-40 w-48  h-screen pt-20 transition-transform bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700 lg:block md:block ${!sidebar && 'hidden'}`}
        aria-label="Sidebar"
      >
        <div className="h-full pb-4 overflow-y-auto bg-white dark:bg-gray-800">
          <ul className="space-y-2 font-medium">
            
            <li>
              <NavLink
                to="/"
                className="flex items-center p-2 text-gray-900  bg-gray-100 group"
              >
                <svg
                  className="w-5 h-5 transition duration-75 dark:text-gray-400 text-black"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 21"
                >
                  <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                  <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                </svg>
                <span className="ms-3">Dashboard</span>
              </NavLink>
            </li>
            {token?.role === 'admin' && <li>
              <NavLink
                to="/leads"
                className="flex items-center p-2 text-gray-900  bg-gray-100 group"
              >
                <i className="fa-solid fa-phone"></i>
                <span className="ms-3">All Leads</span>
              </NavLink>
            </li>}
            {token?.role === 'user' && <li>
              <NavLink
                to="/myleads"
                className="flex items-center p-2 text-gray-900  bg-gray-100 group"
              >
                <i className="fa-solid fa-phone"></i>
                <span className="ms-3">My Leads</span>
              </NavLink>
            </li>}
            
            {token?.role === 'admin' && 
            <li>
              <NavLink
                to="/users"
                className="flex items-center p-2 text-gray-900  bg-gray-100 group"
              >
                <i className="fa-solid fa-user"></i>
                <span className="ms-3">All Users</span>
              </NavLink>
            </li>}
          </ul>
        </div>
      </aside>
      
    </div>
  );
}
