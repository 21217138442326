import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

const Protect = () => {
  const TokenData = localStorage.getItem(process.env.REACT_APP_TOKEN);
  const [access, setAccess] = useState(null); // Set initial access state to null

  useEffect(() => {
    let intervalId;

    const checkExpiration = () => {
      if (TokenData) {
        setAccess(true);
      }else{
        setAccess(false);
      }
    };

    // Initial check
    checkExpiration();

    // Set up interval to check every second (1000 milliseconds)
    intervalId = setInterval(checkExpiration, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [TokenData]);

  if (access === null) {
    // Loading state, you can add a loader or other UI indication here
    return null;
  }
  return access ? <Outlet /> : <Navigate to="/login" />;
};

export default Protect;
