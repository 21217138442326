import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Dashboard from "./components/Dahsboard/Dashboard";
import AllLeads from "./components/Leads/AllLeads";
import Login from "./components/Menu/Login";
import NotFound from "./components/Menu/NotFound";
import Protect from "./protect";
import Lead from "./components/Leads/Lead";
import AllUsers from "./components/Users/AllUsers";
import MyAllLeads from "./components/MyLeads/MyAllLeads";
import MyLead from "./components/MyLeads/MyLead";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/login" Component={Login} />
          <Route element={<Protect />}>
            <Route path="/" Component={Dashboard} />
            <Route path="/leads" Component={AllLeads} />
            <Route path="/leads/:id" Component={Lead} />
            <Route path="/users" Component={AllUsers} />
            <Route path="/myleads" Component={MyAllLeads} />
            <Route path="/myleads/:id" Component={MyLead} />
          </Route>
          <Route path="*" Component={NotFound} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
