import React, { useEffect, useState } from "react";
import Header from "../Menu/Header";
import axios from "axios";
import gifloading from "../../assets/img/loading.gif";
import { Link } from "react-router-dom";

export default function AllLeads() {
  const [loading, setloading] = useState(false);
  const [data, setData] = useState([]);

  const getAllData = async () => {
    setloading(true);
    try {
      const response = await axios.get(
        "https://actofcompassionawards.com/wp-json/custom/v1/wpforms"
      );
      setloading(false);
      if (response?.status === 200 && response?.data) {
        setData(response?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="dark:bg-gray-800 dark:text-white min-h-[91.1vh]">
      <Header />
      <section className="lg:ml-48 md:ml-48 pt-16">
        <h1 className="text-3xl text-center font-extrabold">All Forms Here</h1>
        {!loading && (
          <div
            className="m-5 border max-w-screen"
            style={{ overflowX: "auto" }}
          >
            <table className="w-full text-left">
              <tr className="bg-blue-900 text-white">
                <th className="px-4 py-1">Sr</th>
                <th className="px-4 py-1">Name</th>
                <th className="px-4 py-1">Records</th>
                <th className="px-4 py-1">Action</th>
              </tr>
              {data?.length > 0 &&
                data?.map((item, index) => (
                  <tr
                    className="border-b hover:bg-gray-100 dark:hover:bg-gray-900"
                    key={index}
                  >
                    <td className="px-4 py-1">{index + 1}</td>
                    <td className="px-4 py-1">{item.form_name}</td>
                    <td className="px-4 py-1">{item.total_entries}</td>
                    <td className="px-4 py-1">
                      <Link
                        className="font-bold bg-green-600 text-white rounded px-6"
                        to={`/leads/${item.form_id}`}
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        )}

        {loading && (
          <div className="p-20 bg-gray-50 dark:bg-transparent dark:border dark:border-white">
            <img
              src={gifloading}
              alt="loading..."
              className="w-[100px] m-auto"
            />
          </div>
        )}
      </section>
    </div>
  );
}
